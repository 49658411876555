import { useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";

declare global {
    interface Window {
        dataLayer: any[];
        gtag: (...args: any[]) => void;
    }
}

const InitialUrlDetector = () => {

    const location = useLocation();
    const initialPath = useRef(location.pathname);

    useEffect(() => {

        const waitForGtag = (callback, interval = 100, timeout = 5000) => {
            const startTime = Date.now();

            const checkGtag = () => {
                if (typeof window.gtag === "function") {
                    callback();
                } else if (Date.now() - startTime < timeout) {
                    setTimeout(checkGtag, interval);
                } else {
                    console.warn("gtag.js did not load within timeout.");
                }
            };

            checkGtag();
        };

// Usage
        waitForGtag(() => {
            const hasVisited = sessionStorage.getItem("visitedBefore");

            console.log("Google Analytics is ready!");
            if(!hasVisited) {
                gtag('event', 'landing_page', {
                    url_path: initialPath.current,
                });
                console.log("Tagged Initial Path:", initialPath.current);
                sessionStorage.setItem("visitedBefore", "true");
            }
            else {
                console.log("Page refreshed, skipping tracking.");
            }
        });
    }, []); // Empty dependency array ensures it runs only on mount

    return null; // This component does not render anything
};

export default InitialUrlDetector;